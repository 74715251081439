



























import { Component, Vue } from "vue-property-decorator";
import Store from "@/store";
//components
import DiagnosticsKyma from "./DiagnosticsKyma.vue";
import DiagnosticsISO from "./DiagnosticsISO.vue";
import FeatureNotAvailable from "@/components/FeatureNotAvailable.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import LogVariablesModule from "@/store/clients/LogVariables.module";

const LogVariables = getModule(LogVariablesModule, Store);

@Component({
  components: {
    DiagnosticsKyma,
    DiagnosticsISO,
    FeatureNotAvailable,
  },
})
export default class Diagnostics extends Vue {
  DiagnosticViewTypes = [
    {
      component: "DiagnosticsKyma",
      text: "Kyma",
    },
    {
      component: "DiagnosticsISO",
      text: "ISO19030",
    },
  ];
  DiagnosticViewType = this.DiagnosticViewTypes[0];

  onViewTypeChanged(): void {
    console.log("onViewTypeChanged => onViewTypeChanged:");
    localStorage.setItem("DiagnosticViewType", JSON.stringify(this.DiagnosticViewType));
  }

  setDiagnosticViewType(): void {
    const strDiagnosticViewType: any = localStorage.getItem("DiagnosticViewType") ?? this.DiagnosticViewTypes[0];
    if (typeof strDiagnosticViewType === "string") this.DiagnosticViewType = JSON.parse(strDiagnosticViewType);
    else this.DiagnosticViewType = strDiagnosticViewType;
  }

  async created(): Promise<void> {
    if (!LogVariables.currentVesselLogVariables.length) await LogVariables.refreshCurrentVesselLogVariables(parseInt(this.$route.params.vesselId));
    this.setDiagnosticViewType();
  }
}
