




































































































































































































































































































import { Component, Vue, Ref, Prop, PropSync } from "vue-property-decorator";
//  utilities
import moment from "moment";
import dateHelper from "@/Scripts/utilities/date-helper";
import store from "@/store";
//  components
import ConfirmDialog from "@/components/ConfirmDialog.vue";
//  types
import { ISO19030Event } from "@/types/ISO19030Event";
//  modules
import { getModule } from "vuex-module-decorators";
import ISO19030Module from "@/store/clients/ISO19030.module";
import SnackbarModule from "@/store/clients/Snackbar.module";

const ISO19030 = getModule(ISO19030Module, store);
const Snackbar = getModule(SnackbarModule, store);

interface TableRow {
  id: number;
  eventType: string;
  actions: string[];
  dates: {
    startTimestamp: string | null;
    endTimestamp: string | null;
  };
}

@Component({
  components: {
    ConfirmDialog,
  },
})
export default class VesselEventsTable extends Vue {
  @PropSync("eventsList", { required: true }) syncEvents!: any;
  @Prop({ default: 800 }) maxWidth!: number;
  @Prop() dataMinDate!: string;
  @Ref("confirmDelete") confirmDelete!: any;

  isLoading = false;
  eventModal = false;
  dateMenu = false;
  indockingDateMenu = false;
  outdockingDateMenu = false;
  isEventEditing = false;
  eventTypes = ["Docking", "Maintenance"];
  event: ISO19030Event = {
    id: 0,
    vesselId: this.vesselId,
    startTimestamp: null,
    endTimestamp: null,
    eventType: "Maintenance", // by default
    maintenanceThreshold: null,
    comment: "",
  };
  tableHeaders = [
    { text: "Date", align: "start", sortable: false, value: "dates" },
    { text: "Type", align: "start", sortable: false, value: "eventType" },
    { text: "Actions", align: "start", sortable: false, value: "actions" },
  ];

  thresholdValidation = {
    required: (value: string) => !!value || "Maintenance Threshold required",
  };

  dateValidation = {
    required: (value: string) => !!value || "Event date required",
  };

  typeSelectValidation = {
    required: (value: any) => !!value || "Type required",
  };

  get vesselId(): number {
    return Number(this.$route.params.vesselId);
  }

  get isEventDryDocking(): boolean {
    return this.event.eventType === "Docking";
  }

  get isEventMaintenance(): boolean {
    return this.event.eventType === "Maintenance";
  }

  get isNewEvent(): boolean {
    if (!this.event.id) return true;
    return Boolean(!this.syncEvents.find((event: ISO19030Event) => event.id === this.event.id));
  }

  get isSaveButtonDisabled(): boolean {
    if (this.isEventMaintenance) return Boolean(this.event.eventType === null || this.event.startTimestamp === null);
    else return Boolean(this.event.eventType === null || this.event.startTimestamp === null || this.event.endTimestamp === null || this.event.maintenanceThreshold === null);
  }

  get formattedDataMinDate(): string {
    return this.dataMinDate ? dateHelper.getFormatedDateString(this.dataMinDate) : "N/A";
  }

  get tableRows(): TableRow[] {
    if (!this.syncEvents.length) return [];
    const tableRows: TableRow[] = [];

    this.syncEvents.forEach((event: any) => {
      tableRows.push({
        id: event.id,
        eventType: event.eventType,
        actions: ["edit", "delete"],
        dates: {
          startTimestamp: event.startTimestamp,
          endTimestamp: event.endTimestamp,
        },
      });
    });
    return tableRows;
  }

  formatEventDate(item: any): string {
    if (item.dates.startTimestamp && item.dates.endTimestamp) {
      return `${moment.utc(item.dates.startTimestamp).format("DD.MMM YYYY")} - ${moment.utc(item.dates.endTimestamp).format("DD.MMM YYYY")}`;
    } else {
      return moment.utc(item.dates.startTimestamp).format("DD.MMM YYYY");
    }
  }

  openEventModal(mode: string, row: TableRow): void {
    this.isEventEditing = mode ? mode === "edit" : false;
    if (this.isEventEditing) {
      //  incoming format "2020-03-16T00:00:00" is not compatible with datepicker
      this.event = this.syncEvents.find((e: ISO19030Event) => e.id === row.id);
      this.event.startTimestamp = moment(this.event.startTimestamp).format("YYYY-MM-DD");
      this.event.endTimestamp = moment(this.event.endTimestamp).format("YYYY-MM-DD");
    }
    this.eventModal = true;
  }

  async saveEvent(): Promise<void> {
    try {
      if (this.isEventEditing) {
        await ISO19030.updateEvent(this.event);
        this.$emit("eventUpdated", true);
      } else await ISO19030.createEvent(this.event);
      this.eventModal = false;
      Snackbar.showSnackbar({ text: `Event <b>${this.event.eventType}</b> was <b>${this.isEventEditing ? "updated" : "created"}</b>`, color: "success" });
      this.resetFormData();
    } catch (err) {
      Snackbar.showSnackbar({ text: "Failed to create event" });
    }
  }

  async deleteEvent(row: TableRow): Promise<void> {
    const confirmDelete = await this.confirmDelete.open(`Delete ${row.eventType} | ${this.formatEventDate(row)}`, "Are you sure you want to delete this event?", { titleFontSize: "1.05rem" });
    if (!confirmDelete) return;

    try {
      await ISO19030.deleteEvent(row.id);
      this.eventModal = false;
      Snackbar.showSnackbar({ text: `Event <b>'${row.eventType}'</b> was <b>deleted</b>`, color: "success" });
      this.resetFormData();
    } catch (err) {
      Snackbar.showSnackbar({ text: "Failed to create event" });
    }
  }

  cancel(): void {
    this.resetFormData();
    this.eventModal = false;
  }

  resetFormData(): void {
    this.event = {
      id: 0,
      vesselId: this.vesselId,
      startTimestamp: null,
      endTimestamp: null,
      eventType: null,
      maintenanceThreshold: null,
      comment: "",
    };
  }
}
