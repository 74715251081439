var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.featureEnabled)?_c('div',[(_vm.isLoading)?[_c('v-skeleton-loader',{attrs:{"type":"table","max-width":"800"}}),(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"image, list-item-two-line, image, list-item-two-line, image, list-item-two-line"}}):_vm._e()]:[_c('v-expansion-panels',{staticStyle:{"max-width":"800px"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"white--text",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $expand ")])]},proxy:true}],null,false,2794704483)},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v("Events")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-3 font-weight-semibold",attrs:{"small":"","color":"white","max-width":"150"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.createEvent()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" New event ")],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.eventTableHeaders,"items":_vm.eventTableRows,"dense":"","loading-text":"Loading... Please wait","no-data-text":"No events","hide-default-footer":"","disable-pagination":"","fixed-header":"","sort-by":"date","sort-desc":true},scopedSlots:_vm._u([{key:"header.date",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.type",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"primary--text"},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("Event can be either 'Trend event' or 'Info event'")])])]}},{key:"header.origin",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"primary--text"},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("Where was the event created")])])]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(header.text))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatEventDate(item.date))+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":item.type === 'TrendEvent' ? 'success' : 'info'}},on),[_vm._v(" "+_vm._s(item.type === "TrendEvent" ? "mdi-alpha-t-circle" : "mdi-alpha-i-circle")+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.type))])])]}},{key:"item.origin",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(_vm._s(item.origin === "Office" ? "mdi-home" : "mdi-ferry"))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.origin))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.editEvent(item.event)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit event")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.deleteEvent(item.event)}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete event")])])]}}],null,true)})],1)],1)],1),_c('v-divider',{staticClass:"my-3"}),_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-1 font-weight-semibold",attrs:{"small":"","href":"#","color":"accent"},on:{"click":function($event){$event.preventDefault();return _vm.exportExcel()}}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" Export all ")],1)]}}],null,false,443364442)},[_c('span',[_vm._v("Export all diagnostics data to Excel")])]),_c('v-checkbox',{staticClass:"pt-0 mt-1",attrs:{"label":"Show speed loss trend as displayed on the vessel"},model:{value:(_vm.showSpeedLogDeviation),callback:function ($$v) {_vm.showSpeedLogDeviation=$$v},expression:"showSpeedLogDeviation"}})],1),_c('ConfirmDialog',{ref:"confirmDelete"}),_c('EditVesselEvent',{ref:"editVessel",attrs:{"isOpen":_vm.EditEventOpen},on:{"update:isOpen":function($event){_vm.EditEventOpen=$event},"update:is-open":function($event){_vm.EditEventOpen=$event}}}),_c('DiagnosticsDetails',{ref:"diagnosticsDetails"}),_c('v-snackbar',{attrs:{"timeout":_vm.notification.duration,"color":"primary"},model:{value:(_vm.notification.visible),callback:function ($$v) {_vm.$set(_vm.notification, "visible", $$v)},expression:"notification.visible"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v("mdi-check")]),_vm._v(_vm._s(_vm.notification.message)+" ")],1),_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.vesselLongTrends),function(trend){return _c('DiagnosticCard',{key:trend.longTrend.id,staticClass:"mb-5",attrs:{"longTrendName":trend.longTrend.name,"visible":trend.visible},on:{"plotBandClicked":_vm.showDiagnosticsDetails}})}),1)]],2):_c('FeatureNotAvailable')],1)}
var staticRenderFns = []

export { render, staticRenderFns }