export default function(selectedTimeInMinutes) {
  if (selectedTimeInMinutes <= 1440) {
    return "Raw";
  } else if (selectedTimeInMinutes <= 2880) {
    return "Minute";
  } else if (selectedTimeInMinutes <= 20160) {
    return "QuarterHour";
  } else if (selectedTimeInMinutes <= 129600) {
    return "Hour";
  }

  return "Day";
}
