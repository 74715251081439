















































import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import _ from "underscore";
import autoGranularity from "Utilities/auto-granularity";
//  components
import FeatureNotAvailable from "@/components/FeatureNotAvailable.vue";
import VesselEventsTable from "@/components/Vessel/VesselEventsTable.vue";
import PerformanceIndicatorChart from "@/components/PerformanceIndicatorChart.vue";
//  types
import { ISO19030Event } from "@/types/ISO19030Event.js";
import { PerformanceIndicatorPeriod } from "@/types/PerformanceIndicatorPeriod.js";
import { LogVariable } from "@/types/logVariable.js";
import { LogData } from "@/types/logData.js";
import { ExtendedVessel } from "@/types/Vessel";
//  modules
import { getModule } from "vuex-module-decorators";
import LogVariablesModule from "@/store/clients/LogVariables.module";
import ISO19030Module from "@/store/clients/ISO19030.module";
import LogDataClient from "Clients/log-data-client";
import VesselsModule from "@/store/clients/Vessels.module";

const ISO19030 = getModule(ISO19030Module, store);
const LogVariables = getModule(LogVariablesModule, store);
const Vessels = getModule(VesselsModule, store);

@Component({
  components: {
    FeatureNotAvailable,
    VesselEventsTable,
    PerformanceIndicatorChart,
  },
})
export default class DiagnosticsISO extends Vue {
  isLoading = false;
  performanceIndicatorsLogVariable: LogVariable = {} as LogVariable;
  ISO19030PerformanceLogData: LogData = {} as LogData;
  ISOChartTypes = ["DryDocking Performance", "InService Performance", "Maintenance Trigger", "Maintenance Effect"];
  isAnyEventUpdated = false;

  get vessel(): ExtendedVessel | null {
    return Vessels.currentVessel;
  }

  get featureEnabled(): boolean {
    if (!this.vessel) return false;
    return this.vessel.features.some(feature => feature.name === "Iso19030");
  }

  get vesselId(): number {
    return Number(this.$route.params.vesselId);
  }

  get eventsByVesselId(): ISO19030Event[] {
    return ISO19030.eventsByVesselId(this.vesselId);
  }

  get performanceIndicators(): PerformanceIndicatorPeriod[] {
    return ISO19030.performanceIndicators;
  }

  getISOChartTypePeriods(type: string): PerformanceIndicatorPeriod[] {
    switch (type) {
      case "DryDocking Performance":
        return this.performanceIndicators.filter(item => item.chartName === "DryDocking Performance");
      case "InService Performance":
        return this.performanceIndicators.filter(item => item.chartName === "InService Performance");
      case "Maintenance Trigger":
        return this.performanceIndicators.filter(item => item.chartName === "Maintenance Trigger");
      case "Maintenance Effect":
        return this.performanceIndicators.filter(item => item.chartName === "Maintenance Effect");
      default:
        return [] as PerformanceIndicatorPeriod[];
    }
  }

  get ISO19030PerformanceLogVariable(): LogVariable {
    return LogVariables.logVariableByName("ISO19030 Performance Value");
  }

  async fetchPerformanceIndicatorsData(): Promise<void> {
    if (_.isEmpty(this.ISO19030PerformanceLogVariable)) return;
    const minDate = this.ISO19030PerformanceLogVariable.logDataMinDate ?? "";
    const maxDate = this.ISO19030PerformanceLogVariable.logDataMaxDate ?? "";
    const selectedTimeInMinutes = this.minutesDiff(new Date(minDate), new Date(maxDate));

    this.ISO19030PerformanceLogData = await LogDataClient.findLogData(this.ISO19030PerformanceLogVariable.id, minDate, maxDate, autoGranularity(selectedTimeInMinutes));
  }

  minutesDiff(fromDate: Date, toDate: Date): number {
    const diff = (fromDate.getTime() - toDate.getTime()) / 1000 / 60;

    return Math.abs(Math.round(diff));
  }

  async initViewData(): Promise<void> {
    this.isLoading = true;
    try {
      await Promise.all([await ISO19030.fetchEvents(), await ISO19030.fetchPerformanceIndicators(this.vesselId), await this.fetchPerformanceIndicatorsData()]);
    } catch (error) {
      console.warn(error);
    } finally {
      this.isLoading = false;
    }
  }

  eventUpdated(state: boolean): void {
    this.isAnyEventUpdated = state;
  }

  async created(): Promise<void> {
    await this.initViewData();
  }
}
